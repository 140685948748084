<template>
  <div class="card">
    <div class="card-body">
      <a-page-header
        title="Importer des élèves"
        sub-title="Importer des élèves en suivant la fromat dans la template"
      >
        <template slot="tags">
          <a-tag color="blue"> Excel </a-tag>
        </template>

        <template slot="extra">
          <a-button type="primary" @click="download">
            <a-icon type="file-excel" @click="download" /> Télécharger la
            template Excel
          </a-button>
        </template>
      </a-page-header>
      <div class="row">
        <div class="col-md-9">
          <a-upload-dragger
            :multiple="false"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :customRequest="addTofileList"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="file-excel" />
            </p>
            <p class="ant-upload-text">
              Cliquez ou faites glisser un fichier dans cette zone pour le
              télécharger
            </p>
          </a-upload-dragger>
        </div>
        <div class="col-md-3 p-3 mb-2 bg-light bg-gradient">
          <a-steps direction="vertical" size="small" :current="step">
            <a-step
              title="Importer"
              description="Importer votre fichier excel"
            />
            <a-step title="Affectation" description="Affecter les parents" />
            <a-step
              title="Enregistrer"
              description="Vérifier puis enregistrer"
            />
          </a-steps>
        </div>
        <div class="col-md-12 mt-2">
          <a-divider></a-divider>

          <a-page-header
            title="Vérifier vos données"
            sub-title="Affecter les parents"
          >
            <template slot="tags">
              <a-tag color="blue"> Section parent dans le tableaux </a-tag>
            </template>
            <a-descriptions size="small" :column="3">
              <a-descriptions-item label="Total des élèves">
                {{ studentsData.length }}
              </a-descriptions-item>
              <a-descriptions-item label="Total des parents">
                {{ parentsData.length }}
              </a-descriptions-item>
              <a-descriptions-item label="Eléves non affecté">
                {{ eleveNotAffacted }}
              </a-descriptions-item>
              <a-descriptions-item label="Eléves non affecté">
                <a-icon
                  type="warning"
                  theme="filled"
                  class="m-2"
                  :style="{ color: '#cc3300', fontSize: '26px' }"
                />
              </a-descriptions-item>
              <a-descriptions-item
                label="A vérifier ,plusieurs parents avec le même nom peuvent être affectés"
              >
                <a-icon
                  type="warning"
                  theme="filled"
                  class="m-2"
                  :style="{ color: '#ffcc00', fontSize: '26px' }"
                />
              </a-descriptions-item>
            </a-descriptions>
          </a-page-header>
          <a-table
            :data-source="studentsData"
            :columns="columns"
            :loading="loading"
            :pagination="{
              'show-size-changer': true,
            }"
          >
            <template slot="name" slot-scope="text, record">
              {{ record.firstName + " " + record.lastName }}
            </template>
            <template slot="key" slot-scope="text">
              {{ Number(text) + 1 }}
            </template>
            <template slot="gender" slot-scope="text">
              {{ text === "male" ? "Garçon" : "Fille" }}
            </template>
            <template slot="BirthDate" slot-scope="text">
              {{ moment(text).format("DD-MM-YYYY") }}
            </template>
            <div slot="indice" slot-scope="text, record">
              <a-tooltip v-if="record.moreThanOneFatherMatched == 1">
                <template slot="title">
                  Plusieurs parents avec le même nom peuvent être affectés
                </template>
                <a-icon
                  type="warning"
                  theme="filled"
                  class="m-2"
                  :style="{ color: '#ffcc00', fontSize: '26px' }"
                />
              </a-tooltip>
              <a-tooltip v-else-if="record.moreThanOneFatherMatched == -1">
                <template slot="title"> Eléve non affecté </template>
                <a-icon
                  type="warning"
                  theme="filled"
                  class="m-2"
                  :style="{ color: '#cc3300', fontSize: '26px' }"
                />
              </a-tooltip>
            </div>
            <div slot="parent" slot-scope="text, record">
              <!-- <a-select
                show-search
                placeholder="Affecter un parent"
                style="width: 200px"
                @change="(value) => handleChange(value, record)"
                option-filter-prop="children"
                :filter-option="filterOption"
                :defaultValue="record.parent ? record.parent.key : undefined"
              >
                <a-select-option
                  v-for="parent in parentsData"
                  :key="parent.key"
                  :value="parent.key"
                >
                  {{ parent.father.firstName + " " + parent.father.lastName }}
                </a-select-option>
              </a-select>-->

              <multiselect
                :value="record.parent ? record.parent : undefined"
                @select="(value, id) => handleChange(value, record)"
                placeholder="Affecter un parent"
                selectLabel="Affecter un parent"
                :options="parentsData"
                :custom-label="customLabel"
                :close-on-select="true"
                :preserve-search="true"
                label="key"
                track-by="key"
                :preselect-first="false"
                :multiple="false"
              >
              </multiselect>
            </div>
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="$t('paiement.chercher') + ` ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
          </a-table>
        </div>
        <div class="col-md-12 mt-2">
          <a-button
            type="primary"
            style="width: 100%"
            :disabled="disableUploadBtn"
            :loading="disableUploadBtn"
            @click="addStudents"
          >
            <a-icon type="usergroup-add" />{{ $t("action.enregistrer") }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import * as XLSX from "xlsx/xlsx.mjs";
/* load 'fs' for readFile and writeFile support */
import moment from "moment";
import { mapState } from "vuex";
import router from "@/router";

const validInput = (item) => {
  if (
    !item.BirthDate ||
    !isNaN(new Date(item.BirthDate)) ||
    !["male", "female"].includes(item.gender)
  )
    return false;
  return true;
};

export default {
  computed: {
    ...mapState(["settings"]),
  },
  data() {
    return {
      fileList: [],
      xlsxFile: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      studentsData: [],
      parentsData: [],
      loading: false,
      eleveNotAffacted: 0,
      disableUploadBtn: false,
      step: 0,
      error: {
        parent: 0,
        student: 0,
      },
      columns: [
        {
          title: "",
          dataIndex: "key",
          key: "key",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "key",
          },
          onFilter: (value, record) =>
            record.key.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "Nom et Prénom",
          dataIndex: "firstName",
          key: "name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()) ||
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "Date de naissance",
          dataIndex: "BirthDate",
          key: "BirthDate",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "BirthDate",
          },
          onFilter: (value, record) =>
            record.BirthDate.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Addresse",
          dataIndex: "adress",
          key: "adress",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.adress
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Sexe",
          dataIndex: "gender",
          key: "gender",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "gender",
          },
          onFilter: (value, record) =>
            record.gender
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Téléphone",
          dataIndex: "phone",
          key: "phone",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.phone.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Identifiant unique",
          dataIndex: "uniqueId",
          key: "uniqueId",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.uniqueId
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "Parent",
          dataIndex: "parent",
          key: "parent",
          scopedSlots: {
            customRender: "parent",
          },
        },
        {
          title: "",
          dataIndex: "parent",
          key: "indice",
          scopedSlots: {
            customRender: "indice",
          },
        },
      ],
    };
  },
  methods: {
    moment,
    getCorrectedDate(date) {
      function getTimezoneOffsetMS(date) {
        var fullYear = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        var ms = date.getMilliseconds();

        var time = date.getTime();
        var utcTime = Date.UTC(
          fullYear,
          month,
          day,
          hours,
          minutes,
          seconds,
          ms
        );
        var result = time - utcTime;
        return result;
      }
      return new Date(date.getTime() - getTimezoneOffsetMS(date));
    },
    customLabel({ father }) {
      return `${father.lastName} ${father.firstName}`;
    },
    handleChange(value, record) {
      record.parent = this.parentsData.find((e) => e.key === value.key);
      this.eleveNotAffacted = this.studentsData.filter((e) => !e.parent).length;
      this.eleveNotAffacted === 0 ? (this.step = 2) : (this.step = 1);
      record.moreThanOneFatherMatched = 0;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addTofileList() {
      this.fileList = [this.xlsxFile];
    },
    beforeUpload(file) {
      this.loading = true;
      const isExcel = file.type.includes("sheet");
      if (!isExcel) {
        this.$message.error(
          "Vous ne pouvez télécharger que des fichiers excel (.xlsx)!"
        );
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("L'image doit être inférieure à 5 Mo!");
      }
      if (isExcel && isLt2M) {
        this.$message.success(`${file.name} fichier téléchargé avec succès..`);
        this.xlsxFile = file;
        this.step = 1;
        this.handleFilesUpload();
      }
      return isExcel && isLt2M;
    },
    async handleFilesUpload() {
      this.error.student = 0;
      this.error.parent = 0;
      let faultyLines = [];
      try {
        let f = this.xlsxFile;
        //let reader = new FileReader();
        let studentsJson = [];
        const ab = await f.arrayBuffer();
        //let data = e.target.result;
        let workbook = XLSX.read(ab, {
          type: "binary",
          cellDates: true,
          cellNF: false,
        });

        // Get worksheet parents
        let worksheetParent = workbook.Sheets[workbook.SheetNames[1]];
        let parentsJson = XLSX.utils.sheet_to_json(worksheetParent);
        let activeBuilding = this.settings.activeBuilding;
        parentsJson = parentsJson.map((elem, index) => {
          this.parentValidation(elem);
          let parent = {
            key: index,
            father: {
              firstName: elem["Prénom pére"],
              lastName: elem["Nom pére"],
              phone: elem["Téléphone père"],
              job: elem["Fonction père"],
            },
            mother: {
              firstName: elem["Prénom mère"],
              lastName: elem["Nom mère"],
              phone: elem["Téléphone mère"],
              job: elem["Fonction mère"],
            },
            email: elem["email"],
            adress: elem["Adresse"],
            buildingDB: activeBuilding,
          };
          return parent;
        });
        this.parentsData = parentsJson;
        // Get worksheet student
        let worksheetStudent = workbook.Sheets[workbook.SheetNames[0]];

        studentsJson = XLSX.utils.sheet_to_json(worksheetStudent);
        studentsJson = studentsJson.map((elem, index) => {
          this.studetnValidation(elem);
          let matchFather = this.parentsData.filter((item) =>
            (item.father?.lastName?.toLowerCase() || "").includes(
              elem["Nom"].toLowerCase()
            )
          );

          let student = {
            key: index,
            firstName: elem["Prénom"],
            lastName: elem["Nom"],
            adress: elem["Adresse"],
            BirthDate: elem["Date de naissance"],
            uniqueId: elem["Identifiant unique"],
            gender:
              elem["Sexe"] == "F" || elem["Sexe"] == "f" ? "female" : "male",
            phone: elem["Téléphone"],
            schoolarYearsHistory: {},
            parent: matchFather[0],
            moreThanOneFatherMatched:
              matchFather.length === 0 ? -1 : matchFather.length > 1 ? 1 : 0,
          };
          student.schoolarYearsHistory[this.settings.activeSchoolarYear] = null;

          return student;
        });

        this.studentsData = studentsJson;
        let i = 2;
        this.studentsData = this.studentsData.map((item) => {
          if (validInput(item)) faultyLines.push(i);
          try {
            this.getCorrectedDate(item.BirthDate);
          } catch (error) {
            return item;
          } finally {
            i += 1;
          }
          return {
            ...item,
            BirthDate: this.getCorrectedDate(new Date(item.BirthDate)),
          };
        });
        this.eleveNotAffacted = this.studentsData.filter(
          (e) => !e.parent
        ).length;
        this.eleveNotAffacted === 0 ? (this.step = 2) : (this.step = 1);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.$message.error(
          "format fichier invalide, Veuillez télécharger le fichier et y remplir !"
        );
      }
      if (faultyLines.length > 0) {
        this.$notification.error({
          message: "échec de validation  - feuille élèves",
          description: `Vérifier les données dans ${
            faultyLines.length == 1
              ? "la ligne: " + faultyLines[0]
              : "les lignes: " + "".concat(faultyLines.map((i) => `\n - ${i}`))
          } , dans le fichier excel..`,
        });
      }
    },
    studetnValidation(elem) {
      if (
        !elem["Prénom"] ||
        !elem["Nom"] ||
        !elem["Date de naissance"] ||
        !elem["Sexe"]
      ) {
        console.log("studetnValidation error :", elem, elem.__rowNum__);
        this.error.student += 1;
        this.$notification.error({
          message: "échec de validation  - feuille élèves",
          description: `Vérifier les données obligatoire dans la ligne ${
            elem.__rowNum__ + 1
          } , dans le fichier excel..`,
        });
      }
      if (!this.isDateValid(elem["Date de naissance"])) {
        this.error.student += 1;
        this.$notification.error({
          message: "échec de validation  - feuille élèves",
          description: `Vérifier la format de la date de naissance dans la ligne ${
            elem.__rowNum__ + 1
          } , ${elem["Date de naissance"]} dans le fichier excel..`,
        });
      }
    },
    parentValidation(elem) {
      if (
        !elem["Prénom pére"] ||
        !elem["Nom pére"] ||
        !elem["Téléphone père"] ||
        !elem["Prénom mère"] ||
        !elem["Nom mère"]
      ) {
        this.error.parent += 1;
        this.$notification.error({
          message: "échec de validation - feuille parents",
          description: `Vérifier les données obligatoire dans la ligne ${
            elem.__rowNum__ + 1
          } , dans le fichier excel.`,
        });
      }
    },
    isDateValid(dateString) {
      return moment(dateString, "DD-MM-YYYY", false).isValid();
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    download() {
      const url = "resources/template.xlsx";
      window.location.href = url;
    },
    async addStudents(e) {
      if (this.error.student > 0) {
        this.$message.warning(
          "Veillez vérifier les données obligatoires des élèves"
        );
        return;
      } else if (this.error.parent > 0) {
        this.$message.warning(
          "Veillez vérifier les données obligatoires des parents"
        );
        return;
      }
      if (this.studentsData.filter((e) => !e.parent).length > 0) {
        this.$message.warning("Veillez affecter un parent a chaque élèves ");
        return;
      }
      this.disableUploadBtn = true;
      /*  let student = values;
          student.schoolarYearsHistory = {};
          student.schoolarYearsHistory[this.settings.activeSchoolarYear] =
            values.classRoom ? values.classRoom : null;
          if (this.newParent) {
            const data = values.parentdata;
            await apiClient.put("/parents", { data }).then((res) => {
              const { parent } = res.data;
              delete student.parentdata;
              student.parent = parent._id;
              this.parentsList.push(parentFormater(parent));
            });
            this.newParent = false;
          }*/
      await apiClient
        .put("/students/importer", {
          students: this.studentsData,
          // parents: this.parentsData,
        })
        .then((res) => {
          this.$message.success("Liste importé avec succées");
          router.push(`/elevesliste`).catch((error) => {
            console.error(error);
          });
        })
        .catch((e) => {
          console.error(e);
          this.$message.warning(
            "Veillez vérifier les formats des données , les champs obligatoires et l'affectation des parents a chaque élèves "
          );
        })
        .finally(() => {
          this.disableUploadBtn = false;
        });
    },
  },
};
</script>
